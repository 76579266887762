<template>
  <div class="coin-withdraw">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    
    <van-tabs v-model:active="tabActive" :type="$AppData.config.themeType ? 'card' : 'line'">
      <van-tab :title="$t('tabTitle.withdrawal')">
        <div class="withdraw-content">
          <div class="top-title">{{$AppData.config.appCoin}}</div>
          <div class="form-wrap">
            <div class="channel-wrap">
              <div
                v-for="networking in withdrawInfo.netList"
                :key="networking"
                :class="`channel-tag ${networking === activeNetworking ? 'active' : ''}`"
                @click="activeNetworking = networking"
              >{{networking}}</div>
            </div>
            <div class="form-title">{{$t('field.address')}}</div>
            <div class="field-wrap">
              <!-- <van-field
                v-model="qrCode"
                class="form-field with-suffix"
                size="large"
                maxlength="64"
                clearable
                :placeholder="$t('placeholder.default')"
              />
              <div class="suffix-wrap" @click="goToQrcode">
                <van-icon name="scan" size="24" />
              </div> -->
							<van-field
							  :model-value="columns[addressIndex]"
							  class="form-field with-suffix address-text"
							  readonly
							  @click="checkShowPicker"
							/>
              <div class="suffix-wrap" @click="goToUserAddress">
                <van-icon name="orders-o" size="22" />
              </div>
            </div>
						<div class="field-wrap">
						</div>
            <div class="form-title">{{$t('field.amount')}}</div>
            <div class="field-wrap">
              <van-field
                v-model="amount"
                @input="inputAmount"
                class="form-field with-suffix"
                type="number"
                size="large"
                :placeholder="$t('placeholder.default')"
              />
              <!-- <div class="suffix-wrap">
                <span>{{$AppData.config.appCoin}}</span>
              </div> -->
            </div>
            <div class="field-extra">{{$t('field.availableBalance')}} <span class="highlight">{{withdrawInfo.balanceStr}}</span> {{$AppData.config.appCoin}}</div>
          </div>
          <div class="page-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              @click="handleCoinWithdraw"
              :disabled="!amount || !activeNetworking || !addressId"
            >{{$t('button.confirm')}}</van-button>
          </div>
          <router-link class="history-btn" :to="{ name:'coinWithdrawHistory' }">{{$t('title.historyData')}}</router-link>
          <div class="tip-text">{{appTip}}</div>
        </div>
        <van-popup v-model:show="showWithdrawInfo" :close-on-click-overlay="false" position="bottom">
          <div class="withdraw-info-title">
            {{$t('tabTitle.withdrawal')}}
            <span @click="showWithdrawInfo = false">{{$t('common.cancel')}}</span>
          </div>
          <div>
            <van-cell :border="false" :title="$t('field.receivedAmount')" :value="`${preWithdrawData.actualAmountStr} ${$AppData.config.appCoin || ''}`" />
            <van-cell :border="false" :title="$t('field.commission')" :value="`${preWithdrawData.poundageStr} ${$AppData.config.appCoin || ''}`" />
           <!-- <van-field
              v-model="code"
              maxlength="6"
              :label="$t('field.verifyCode')"
              :placeholder="$t('placeholder.verifyCode')"
            /> -->
          </div>
          <div class="pop-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              @click="handleConfirmCoinWithdraw"
            >{{$t('button.submit')}}</van-button>
          </div>
        </van-popup>
      </van-tab>
      <van-tab :title="$t('tabTitle.withdrawProcessing')">
        <van-pull-refresh v-model="refreshing" @refresh="refreshData">
          <div class="order-list">
            <van-list
              v-if="dataList.length >0"
              v-model:loading="loading"
              :finished="finished"
              :finished-text="$t('common.noMore')"
              @load="loadData"
            >
              <div class="journal-block" v-for="item in dataList" :key="item.sn">
                <van-cell-group :border="false">
                  <van-cell :title="$t('field.orderNumber')" :value="item.sn" class="journal-title" value-class="journal-sn"/>
                  <div class="journal-detail">
                    <van-cell :title="$t('field.address')" :value="item.payeeAdd" :border="false" class="journal-detail-row" />
                    <van-cell :title="$t('field.amount')" :value="item.amountStr" :border="false" class="journal-detail-row" />
                    <van-cell :title="$t('field.commission')" :value="item.feeStr" :border="false" class="journal-detail-row" />
                    <template v-if="$AppData.config.themeType">
                      <p :class="`water-mark ${orderStatus(item.status)}`">{{item.statusStr}}</p>
                      <van-cell :title="$t('field.orderTime')" :value="item.createTimeStr" :border="false" class="journal-detail-row" />
                    </template>
                  </div>
                  <van-cell v-if="!$AppData.config.themeType" :title="item.createTimeStr" :value="item.statusStr" :border="false" class="order-date" :value-class="`order-status ${orderStatus(item.status)}`" />
                </van-cell-group>
              </div>
            </van-list>
            <div v-else>
              <van-empty :description="$t('common.noData')"></van-empty>
            </div>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
      
  </div>
	<van-popup v-model:show="showPicker" position="bottom">
	  <van-picker
	    :columns="columns"
	    @confirm="selectCode"
	    @cancel="showPicker = false"
	  />
	</van-popup>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import { getCoinWithdrawInfo, createCoinWithdraw, confirmCoinWithdraw, queryCoinWithdrawHanding, queryPageTip, queryAddressList } from "@/api";

export default {
  setup() {
    const tabActive = ref(0);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
		const addressIndex = ref(0)
		const columns = ref([]);
		const showPicker = ref(false);
		const addressList = ref([]);
		const addressId = ref('');
    return {
      tabActive,
      loading,
      finished,
      refreshing,
      dataList,
			columns,
			showPicker,
			addressIndex,
			addressId,
			addressList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      withdrawInfo: {},
      amount: '',
      appTip: '',
      current: 1,
      activeNetworking: 'TRC20',
      qrCode: '',
      showWithdrawInfo: false,
      preWithdrawData: {},
      code: '',
      ...this.$AppData.dataStore,
    };
  },
  
  mounted() {
    this.$AppData.clearDataStore();
    this.fetchPageTip();
    this.fetchCoinWithdrawInfo()
    this.fetchOrderList();
		this.fetchAddressList();
  },

  methods: {
    fetchPageTip() {
      queryPageTip({
        code: 'WithdrawVirtualFragment',
      }).then(res => {
        this.appTip = res.data.text;
      });
    },
    fetchCoinWithdrawInfo() {
      getCoinWithdrawInfo().then(res => {
        this.withdrawInfo = res.data;
      });
    },
    fetchOrderList() {
      queryCoinWithdrawHanding({
        pageSizeStr: 100,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
		fetchAddressList() {
			queryAddressList().then(res => {
				this.addressList = (res.data || [])
				this.columns = this.addressList.map(item => item.address);
				if(this.columns.length > 0) {
					this.addressId = this.addressList[0].id;
				}
			})
		},
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    inputAmount() {
      this.amount = this.amount.downFixed(4);
    },
    handleCoinWithdraw() {
      createCoinWithdraw({
        amount: this.amount,
        net: this.activeNetworking,
      }).then(res => {
        this.preWithdrawData = res.data;
        this.showWithdrawInfo = true;
      });
    },
    handleConfirmCoinWithdraw() {
      confirmCoinWithdraw({
        token: this.preWithdrawData.token,
        addressId: this.addressId,
        code: this.code
      }).then(res => {
        // localStorage.setItem('coinWithdrawData', JSON.stringify(res.data));
        this.$router.push({ name: 'coinWithdrawSuccess', params: { type: 'withdraw' }});
      });
    },
    orderStatus(status) {
      if(status === 0) {
        return 'green';
      } else if(status === 1){
        return 'red';
      } else {
        return '';
      }
    },
    goToQrcode() {
      this.$AppData.saveDataStore({
        amount: this.amount,
        activeNetworking: this.activeNetworking,
        qrCode: this.qrCode,
      });
      this.$router.push('/qrcode');
    },
    goToUserAddress() {
      this.$router.push('/userAddress');
    },
    goBack() {
      this.$router.back();
    },
		checkShowPicker() {
		  if(this.columns.length > 1) {
		    this.showPicker = true;
		  } 
		},
		selectCode(value, index) {
			this.addressIndex = index;
			this.showPicker = false;
			this.addressId = this.addressList[index].id;
		},
  },
};
</script>

<style lang="less">
.coin-withdraw {
  padding-bottom: 40px;
  .van-tabs__line {
    background: var(--van-primary-color);
  }
  .van-tab {
    &.van-tab--active {
      color: var(--van-primary-color);
    }
    .van-tab__text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .withdraw-content {
    padding: 16px;
    .top-title {
      padding: 16px;
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      background-color: var(--van-primary-color);
      border-radius: 8px 8px 0 0;
    }
    .form-wrap {
      padding: 16px 12px;
      background: #fff;
      border-radius:  0 0 8px 8px;
      overflow: hidden;
      .form-title {
        margin: 16px 0;
        font-size: 14px;
        color: #28293D;
        font-weight: 400;
      }
      .field-wrap {
        position: relative;
        .suffix-wrap {
          position: absolute;
          top: 0;
          right: 12px;
          width: 42px;
          line-height: 46px;
          text-align: right;
          font-weight: 400;
          color: #6F6F93;
          i, span {
            font-size: 16px;
            vertical-align: middle;
          }
          .triangle {
            width: 8px;
            margin-left: 12px; 
            vertical-align: middle;
          }
        }
      }
      .form-field {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        background: #F9FAFB;
        border-radius: 4px;
        &.with-suffix {
          padding-right: 48px;
        }
        &.address-text {
          font-size: 12px;
        }
      }
      .field-extra {
        padding: 12px 2px;
        font-size: 12px;
        color: #6F6F93;
        line-height: 16px;
        font-weight: 400;
        text-align: right;
        .highlight {
          color: var(--van-primary-color);
        }
      }
    }
    .channel-wrap {
      display: flex;
      .channel-tag {
        min-width: 60px;
        padding: 0 10px;
        margin-right: 10px;
        line-height: 28px;
        font-size: 14px;
        color: #fff;
        background: #d1d1d1;
        // border: 1px solid #F9FAFB;
        border-radius: 14px;
        text-align: center;
        box-sizing: border-box;
        &.active {
          font-weight: 700;
          // color: #fff;
          background: var(--van-primary-color);
          // border: 1px solid var(--van-primary-color);
        }
      }
    }
    .page-button-wrap {
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // width: 100%;
      padding: 16px 0;
      // background: #fff;
      box-sizing: border-box;
      .van-button {
        border-radius: 26px;
      }
    }
    .tip-text {
      padding: 16px;
      font-size: 12px;
      color: #646566;
    } 
  }
  .withdraw-info-title {
    margin: 0 15px;
    line-height: 60px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    border-bottom: 1px dashed #efefef;
    span {
      display: block;
      float: right;
      font-size: 14px;
      color: #6F6F93;
      text-align: left;
    }
  }
  .history-btn {
    display: block;
    padding: 10px;
    font-size: 14px;
    color: #6F6F93;
    text-align: center;
  }
}
.theme1 {
  .coin-withdraw {
    .van-tabs--card {
      padding-top: 20px;
    }
    .van-tabs__content {
      margin: 0 16px;
      background: #fff;
      border-radius: 0 0 9px 9px;
      box-shadow: 0px 4px 16px 0px #EBEBEB;
    }
    .withdraw-content {
      .top-title {
        padding: 16px 0;
        font-size: 16px;
        color: var(--van-primary-color);
        background: transparent;
        font-weight: 700;
      }
      .form-wrap { padding: 0;}
    }
  }
}
</style>
